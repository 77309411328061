import { Node } from '@tiptap/core';

export const Emoji = Node.create({
  name: 'emoji',

  group: 'inline',
  inline: true,
  atom: true,

  addAttributes() {
    return {
      code: {
        default: '😊',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-emoji]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', { 'data-emoji': '', ...HTMLAttributes }, HTMLAttributes.code];
  },

  addNodeView() {
    return ({ node }) => {
      const span = document.createElement('span');
      span.classList.add('emoji');
      span.textContent = node.attrs.code;

      return {
        dom: span,
        contentDOM: null,
      };
    };
  },

  addCommands() {
    return {
      insertEmoji:
        (code) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: { code },
          });
        },
    };
  },
});
