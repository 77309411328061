<template lang="pug">
div
  .menu(v-if="editor")
    input(
      type="color"
      @input="editor.chain().focus().setColor($event.target.value).run()"
      :value="editor.getAttributes('textStyle').color"
    )
    button(
      :class="{ 'is-active': editor.isActive('bold') }"
      @click="editor.chain().focus().toggleBold().run()"
    ) Bold
    button(
      :class="{ 'is-active': editor.isActive('italic') }"
      @click="editor.chain().focus().toggleItalic().run()"
    ) Italic
    button(
      :class="{ 'is-active': editor.isActive('bulletList') }"
      @click="editor.chain().focus().toggleBulletList().run()"
    ) Bullet list
    button(@click="insertPlaceholder") Insert placeholder
    button(
      :class="{ 'is-active': editor.isActive('fontFamily', { fontFamily: 'Inter' }) }"
      @click="editor.chain().focus().setFontFamily('Inter').run()"
    ) Inter
    button(@click="editor.chain().focus().unsetFontFamily().run()") Unset font family

    button#emoji-button(@click="showEmojiPopover()") 😊
    #emoji-popover(
      style="display: none; position: absolute; background: white; border: 1px solid #ddd; padding: 10px; border-radius: 4px"
    )
  editor-content.ml-2.mt-2(:editor="editor")
</template>

<script>
  import { Editor, EditorContent } from '@tiptap/vue-2';
  import { Color } from '@tiptap/extension-color';
  import StarterKit from '@tiptap/starter-kit';
  import Bold from '@tiptap/extension-bold';
  import Paragraph from '@tiptap/extension-paragraph';
  import Heading from '@tiptap/extension-heading';
  import Text from '@tiptap/extension-text';
  import { FontFamily } from '@/views/TipTap/FontFamily';
  import { Placeholder } from '@/views/TipTap/Placeholder';
  import { Emoji } from '@/views/TipTap/Emoji';
  import TextStyle from '@tiptap/extension-text-style';
  import BulletList from '@tiptap/extension-bullet-list';

  const CustomBold = Bold.extend({
    renderHTML(params) {
      // Original:
      // return ['strong', HTMLAttributes, 0]
      params.HTMLAttributes.class = 'my-custom-bold';
      params.HTMLAttributes.style = 'font-weight: 700';
      return ['span', params.HTMLAttributes, 0];
    },
  });

  export default {
    components: {
      EditorContent,
    },

    data() {
      return {
        editor: null,
      };
    },

    mounted() {
      this.editor = new Editor({
        content: '<p>I’m running Tiptap with Vue.js. 🎉</p>',
        extensions: [
          Color,
          BulletList,
          StarterKit,
          CustomBold,
          Paragraph.configure({
            HTMLAttributes: {
              class: 'my-custom-paragraph',
            },
          }),
          Heading.configure({
            HTMLAttributes: {
              class: 'my-custom-heading',
            },
          }),
          Text,
          TextStyle,
          FontFamily,
          Placeholder,
          Emoji,
        ],
      });

      this.$nextTick(() => {
        this.addEmojiListeners();
      });
    },

    methods: {
      insertPlaceholder() {
        const type = prompt('Enter type');
        const value = prompt('Enter value');
        this.editor.commands.insertPlaceholder({
          type,
          value,
        });
      },
      addEmojiListeners() {
        const popover = document.getElementById('emoji-popover');
        document.addEventListener('click', (event) => {
          const popover = document.getElementById('emoji-popover');
          const button = document.getElementById('emoji-button');

          if (!button.contains(event.target) && !popover.contains(event.target)) {
            popover.style.display = 'none';
          }
        });
        popover.addEventListener('click', (event) => {
          const emoji = event.target.getAttribute('data-emoji');
          if (emoji) {
            this.editor.commands.insertEmoji(emoji);
            popover.style.display = 'none'; // Hide popover after selection
          }
        });
      },
      showEmojiPopover() {
        const button = document.getElementById('emoji-button');
        const popover = document.getElementById('emoji-popover');
        const rect = button.getBoundingClientRect();
        popover.style.display = popover.style.display === 'none' ? 'block' : 'none';
        popover.style.top = `${rect.bottom + window.scrollY}px`;
        popover.style.left = `${rect.left + window.scrollX}px`;

        const emojiList = [
          '😊',
          '😂',
          '😍',
          '😎',
          '😢',
          '😡',
          '👍',
          '👏',
          '🙏',
          '💪',
          '🎉',
          '💖',
          '🔥',
          '💯',
          '✅',
          '❌',
          '✨',
          '🎂',
          '🍕',
          '🍔',
          '🍎',
          '🥑',
          '☕',
          '🍺',
          '🚗',
          '✈️',
          '🏡',
          '📱',
          '💻',
          '📚',
          '⚽',
          '🏀',
          '🏈',
          '🎸',
          '🎮',
          '🎧',
        ];
        const popoverContent = emojiList
          .map((emoji) => `<div class="emoji" data-emoji="${emoji}">${emoji}</div>`)
          .join('');
        popover.innerHTML = popoverContent;
      },
    },

    beforeDestroy() {
      this.editor.destroy();
    },
  };
</script>

<style lang="sass">
  .is-active
    font-weight: bold

  .ql-font-om-inter
    font-family: 'Inter'

  .placeholder
    background-color: #f0f0f0
    border: 1px dashed #ccc
    padding: 2px 4px
    border-radius: 4px

  #emoji-popover
    position: absolute
    background: white
    border: 1px solid #ddd
    padding: 10px
    border-radius: 4px
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
    max-width: 300px
    display: grid
    grid-template-columns: repeat(6, 40px)
    gap: 5px
    justify-content: start
    z-index: 10

    .emoji
      display: inline-block
      padding: 5px
      cursor: pointer
      font-size: 1.5rem

      &:hover
        background: #f0f0f0
        border-radius: 4px
</style>
