import { Node } from '@tiptap/core';

export const Placeholder = Node.create({
  name: 'placeholder',

  group: 'inline',
  inline: true,
  atom: true,

  addAttributes() {
    return {
      type: { default: 'price' },
      value: { default: 100 },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-placeholder]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      { 'data-placeholder': '', ...HTMLAttributes },
      `[[${HTMLAttributes.type}:${HTMLAttributes.value}]]`,
    ];
  },

  addNodeView() {
    return ({ node, getPos, editor }) => {
      const span = document.createElement('span');
      span.contentEditable = false;
      span.classList.add('placeholder');
      span.textContent = `[[${node.attrs.type}:${node.attrs.value}]]`;

      span.addEventListener('click', () => {
        const newValue = prompt('Enter new value:', node.attrs.value);
        if (newValue !== null) {
          editor
            .chain()
            .focus()
            .command(({ tr }) => {
              tr.setNodeMarkup(getPos(), undefined, {
                ...node.attrs,
                value: newValue,
              });
              return true;
            })
            .run();
        }
      });

      return {
        dom: span,
        contentDOM: null,
      };
    };
  },
  addCommands() {
    return {
      insertPlaceholder:
        (attributes) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: attributes,
          });
        },
    };
  },
});
