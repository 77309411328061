import { Mark } from '@tiptap/core';

export const FontFamily = Mark.create({
  name: 'fontFamily',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addAttributes() {
    return {
      fontFamily: {
        default: null,
        parseHTML: (element) => {
          const fontClass = element.className.match(/ql-font-om-(\w+)/);
          return fontClass ? fontClass[1] : null;
        },
        renderHTML: (attributes) => {
          if (!attributes.fontFamily) {
            return {};
          }
          return {
            class: `ql-font-om-${attributes.fontFamily.toLowerCase()}`,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[class^="ql-font-om-"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0];
  },

  addCommands() {
    return {
      setFontFamily:
        (fontFamily) =>
        ({ chain }) => {
          return chain().setMark(this.name, { fontFamily }).run();
        },
      unsetFontFamily:
        () =>
        ({ chain }) => {
          return chain().unsetMark(this.name).run();
        },
    };
  },
});
